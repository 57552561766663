.fondotitle{
  background-color: #004c6b !important;
}
.shadowcards{
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.15),0 2px 10px 0 rgba(0,0,0,0.11)
}
.titlemat{
font-family: Georgia, 'Times New Roman', Times, serif;
font-size: 5rem;
}
.bdb{
border-bottom: 3px #004c6b solid;
}
.zoomwhastapp {
  transition: transform 0.4s;
}
.zoomwhastapp:hover {
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1);
}

.imgprofile{
  position: relative;
  width: 350px;
  height: 350px !important;
  border-radius: 200px;
  border: 10px solid #004c6b;
}
.marginimg{
  padding-top: 50px;
}
.bordera{
  font-weight: bold;
  border-top: 1px #004c6b solid;
  padding-top: 15px;
}
.borderab{
  font-weight: bold;
  border-top: 1px #004c6b solid;
  padding-top: 15px;
  border-bottom: 1px #004c6b solid;
  padding-bottom: 15px;
}
.spacewords{
  word-spacing: 1px;
  letter-spacing: 2px;
}

@media (max-width: 1560px){
  .imgprofile{
      position: relative;
      width: 250px;
      height: 250px !important;
      border-radius: 200px;
      border: 10px solid #004c6b;
  }
}

@media (max-width: 1440px){
  .imgprofile{
      position: relative;
      width: 250px;
      height: 250px !important;
      border-radius: 200px;
      border: 10px solid #004c6b;
  }
}


@media (max-width: 1024px){
  .imgprofile{
      position: relative;
      width: 200px;
      height: 200px !important;
      border-radius: 200px;
      border: 10px solid #004c6b;
  }
}


@media (max-width: 768px){
  .imgprofile{
      position: relative;
      width: 250px !important;
      height: 250px !important;
      border-radius: 200px;
      border: 10px solid #004c6b;
  }
}

@media (max-width: 450px){
  .imgprofile{
      position: relative;
      width: 250px;
      height: 250px !important;
      border-radius: 200px;
      border: 10px solid #004c6b;
  }
}

@media (max-width: 375px){
  .imgprofile{
      position: relative;
      width: 250px;
      height: 250px !important;
      border-radius: 200px;
      border: 10px solid #004c6b;
  }
}

@media (max-width: 320px){
  .imgprofile{
      position: relative;
      width: 250px;
      height: 250px !important;
      border-radius: 200px;
      border: 10px solid #004c6b;
  }
}