.nav-item{
    border-radius: 0.25rem;
}
.nav-link{
    background-color: rgb(255, 255, 255);
    color: #004c6b !important;
    padding: 1.2rem 1.2rem !important;
}

.nav-pills .nav-link.active{
    color: white !important;
    background-color: #004c6b !important;
    border: 0 !important;
}

.nav-pills .nav-link:focus {
    border-color: 1px #004c6b solid !important;
}


.borderseparetion{
    border-bottom: 3px  #004c6b solid !important;
    border-radius: 40%;
    margin-right: 20%;
    margin-left: 20%;

}
.btnparcial{
    background-color: #004c6b !important;
}
