.zoomuser {
    transition: transform 0.4s;
  }
  .zoomuser:hover {
    -ms-transform: scale(1.2); /* IE 9 */
    -webkit-transform: scale(1.2); /* Safari 3-8 */
    transform: scale(1.2);
  }
  .paddingalert{
    padding-top: 10vh;
    padding-bottom: 10vh;
  }
  
.title{
  max-width: 100%;
  overflow: hidden;
  max-height: 50px;
}
.heightcontainer{
  height: 100%;
}

.input-view{
  min-width: 50px;
}