.borderhomehistory{
    border-radius: 10px !important;
    border: 3px solid #004c6b !important;
    margin-right: 50px;
    margin-left: 50px;
}
.borderhomeprofile{
    border-radius: 10px;
    border: 4px solid #004c6b;
    margin-right: 50px;
    margin-left: 50px;
    padding-bottom: 0px;
}

.bgprofile{
    background-image: url(../Images/bgprofile.jpg);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}
.newsnotification {
    font-weight: bolder;
    color: #ffc107;
}
.bordernotification{
    width: 40px;
    height: 35px;
    margin-left: 100px;
    background: white;
    border-radius: 40px;
    border: 3px solid #004c6b;
}

@media (max-width: 768px){
    .borderhomeprofilemob{ 
        border-radius: 10px;
        border: 3px solid #004c6b;
        background-color: white;
    }
}

