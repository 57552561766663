.bgscreen{
  height: 100vh;
  width: 100%;
  background: url("../Images/Home/fondo.jpg") center no-repeat;
  background-size: cover;
  background-attachment: fixed; 
}
.titlecampus{
  font-size: 3rem;
}
.bold{
  font-weight: bold;
}

.borderhome{
  border: 2px #004c6b  solid !important;
}

.heighthome{
  height: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.pxb{
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

@media (max-width: 900px){
  .pxb{
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
}

@media (max-width: 450px){
    .bgscreen{
    height:  100vh;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    background: url("../Images/Home/fondo1.jpg") center no-repeat;
    background-size: cover;
    background-attachment: fixed; 
}

.titlecampus{
  font-size: 2rem;
}
  .heighthome{
    height: 80vh;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}