.App {
  text-align: center;
}
.textmaterial{
  font-weight: bold;
  color: #004c6b !important;
  transition: transform .4s;
}
.textmaterial:hover{
  text-decoration: none;
  font-weight: bold;
  color: #004c6b !important;
  -ms-transform: scale(1.07); /* IE 9 */
  -webkit-transform: scale(1.07); /* Safari 3-8 */
  transform: scale(1.07); 
}
.textmaterialdiap{
  font-weight: bold;
  color: #004c6b !important;
  transition: transform .4s;
}
.textmaterialdiap:hover{
  text-decoration: none;
  font-weight: bold;
  color: #004c6b !important;
  -ms-transform: scale(1.07); /* IE 9 */
  -webkit-transform: scale(1.07); /* Safari 3-8 */
  transform: scale(1.07); 
}

.textcolor{
  color: #004c6b !important;
}
.bgcolor{
  background-color: #004c6b !important;
  border-radius: 15px;
  opacity: 0.9;
}


.form-control{
  border-bottom: 2px #004c6b solid !important;
  background-color: rgb(238, 238, 238) !important;
}

.form-check-input{
  color: #004c6b !important;
}

.form-check-label{
  color: #004c6b !important;
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: #004c6b !important;
  background-color: #004c6b !important;
}

.zoomcampus {
  transition: transform 0.4s;
}

.zoomcampus:hover {
  -ms-transform: scale(1.1);
  /* IE 9 */
  -webkit-transform: scale(1.1);
  /* Safari 3-8 */
  transform: scale(1.1);
}