.fondocolor{
  background-color: #efefef;
}   

.titleprof{
  font-size: 3.5rem;
}

.profp{
  width: 35vw;
}


.subprof{
  font-size: 3.5rem;
}

.odx{
  padding-left: 14rem;
  padding-right: 14rem;
}

.portadaProf{
  height: 80vh;
  padding-top: 2rem;
  padding-bottom: 7rem;
  width: 100%;
  background: url("../Images/ProfesorProfile/portadaprof.jpg") center no-repeat;
  background-size: cover; 
}
@media(max-width:1366px){
  .titleprof{
    font-size: 4rem;
  }
  .subprof{
    font-size: 3rem;
  }
  .profp{
    width: 30vw;
  }
  .odx{
    padding-left: 10rem;
    padding-right: 10rem;
  }
}


@media (max-width:990px) {
  .titleprof{
    font-size: 4rem;
    background: #efefef;
  }
  .portadaProf{
    height: 100%;
    padding-top: 7rem;
    padding-bottom: 8rem;
    width: 100%;
    background: url("../Images/ProfesorProfile/fondo2.jpg") center no-repeat;
    background-size: cover;
    background-attachment: fixed; 
  }
}

@media (max-width:450px) {
  .titleprof{
    font-size: 3.5rem;
    background: none;
  }
  .portadaProf{
    height: 100%;
    padding-top: 7rem;
    padding-bottom: 7rem;
    width: 100%;
    background: url("../Images/ProfesorProfile/fondo2.jpg") center no-repeat;
    background-size: cover;
    background-attachment: fixed; 
  }
  .profp{
    width: 80vw;
  }
}

@media(max-width:420px){
  .titleprof{
    font-size: 3rem;
    background: none;
  }
}
