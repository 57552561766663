.borderbottom{
    border-bottom: 2px #004c6b solid;
}

.orange{
    color: #ff9931;
}

.borderheader{
    border-top: 2px #004c6b solid;
    border-bottom: 2px #004c6b solid;
}