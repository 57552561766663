.shadowcard{
    margin-left: 15%;
    margin-right: 15%;
}

.borderprofile{
    border-bottom: 1px #004c6b solid;
}
.paddinguser{
    padding-left: 0%!important;
    padding-right: 0%!important;
}
.imgProfile{
    max-width: 300px;
    max-height: 300px;
    min-width: 300px;
    min-height: 300px;
}

@media (max-width: 425px){
    .shadowcard{
            margin-left: 4%;
            margin-right: 4%;
            box-shadow: 0 2px 5px 0 rgba(0,0,0,0.15),0 2px 10px 0 rgba(0,0,0,0.11)
    }
    .imgProfile{
        width: 200px;
        height: 200px;
    }
}