.borderprofile{
    border-radius: 200px;
    border: 10px solid #004c6b;
}
.borderimage{
    border-radius: 10px;
    border: 5px solid #004c6b;
}
.bghistory{
    background-image: url(../Images/bghistory.jpg);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}
.borderexamhistory{
    border-radius: 10px;
    border: 3px solid #004c6b;
}
.colorgreen{
    color: rgb(1, 117, 1) !important;
}