.backgroundlogin{
    background-image: url(../Images/login.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.borderTitleLogIn{
    border-bottom: 1px #004c6b  solid;
}
.borderLogIn{
    border-top: 1px #004c6b  solid;
    margin-top: 20px;
    padding-top: 20px;
}
.borderinput{
    background-color: rgb(241, 241, 241);
    border: none;
    border-bottom: 2px #004c6b  solid;
    
}
.form-control:focus{
    border-color: none;
    color: black;
    border-bottom: 2px #004c6b  solid;
    box-shadow: none;
    
}